import {CanMatchFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from '../auth.service';

export function canMatchAuthenticated(additionalRoles: string[] = []): CanMatchFn {
  return () => {
    const auth: AuthService = inject(AuthService);
    const roles = ['admin', 'crm', 'crm-sgr', 'crm-bg', ...additionalRoles];

    if (auth.user$.value && roles.includes(auth.user$.value?.role)) {
      return true;
    }

    const router: Router = inject(Router);
    return router.createUrlTree(['/auth/login']);
  };
}
