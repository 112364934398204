<mat-form-field [appearance]="props.appearance ?? 'outline'" class="w-full">
  @if (props.label) {
    <mat-label>{{props.label}}</mat-label>
  }
  <input
    matInput
    [id]="id"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [matDatepicker]="picker"
    [max]="props.datepickerOptions?.max"
    [min]="props.datepickerOptions?.min"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder ?? ''"
    [readonly]="props.readonly"
    [required]="required"
    />
    <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="!!props.disabled"></mat-datepicker-toggle>

    <mat-datepicker
      #picker
      [color]="props.color"
      [disabled]="!!props.datepickerOptions?.disabled"
      [opened]="!!props.datepickerOptions?.opened"
      [startAt]="props.datepickerOptions?.startAt ?? null"
      [startView]="props.datepickerOptions?.startView ?? 'multi-year'"
      [touchUi]="!!props.datepickerOptions?.touchUi"
      (opened)="props.datepickerOptions ? props.datepickerOptions.opened = true : null"
      (closed)="props.datepickerOptions ? props.datepickerOptions.opened = false: null"
      >
    </mat-datepicker>
  </mat-form-field>
