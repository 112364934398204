import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import localeIt from '@angular/common/locales/it';
import {registerLocaleData} from '@angular/common';
import {FormlyModule} from '@ngx-formly/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {
  constValidationMessage,
  exclusiveMaximumValidationMessage,
  exclusiveMinimumValidationMessage,
  maxItemsValidationMessage,
  maxLengthValidationMessage,
  maxValidationMessage,
  minItemsValidationMessage,
  minLengthValidationMessage,
  minValidationMessage,
  multipleOfValidationMessage,
  typeValidationMessage
} from './formly-components/formly-components.module';
import {NullTypeComponent} from './formly-components/null-type/null-type.component';
import {ArrayTypeComponent} from './formly-components/array-type/array-type.component';
import {ObjectTypeComponent} from './formly-components/object-type/object-type.component';
import {MultiSchemaTypeComponent} from './formly-components/multi-schema-type/multi-schema-type.component';
import {EmailValidator} from './formly-components/validators/EmailValidator';
import {SeparatorTypeComponent} from './formly-components/separator-type/separator-type.component';
import {DatepickerTypeComponent} from './formly-components/datepicker-type/datepicker-type.component';
import {MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule} from '@angular/material-luxon-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DatetimepickerTypeComponent} from './formly-components/datetimepicker-type/datetimepicker-type.component';
import {DateNotBeforeFieldValidator} from './formly-components/validators/DateNotBeforeFieldValidator';
import {ButtonToggleTypeComponent} from './formly-components/button-toggle-type/button-toggle-type.component';
import {FormlyMatSliderModule} from '@ngx-formly/material/slider';
import {AutocompleteTypeComponent} from './formly-components/autocomplete-type/autocomplete-type.component';
import {
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER
} from '@angular/material/autocomplete';
import {HiddenInputTypeComponent} from './formly-components/hidden-input-type/hidden-input-type.component';
import {PhoneTypeComponent} from './formly-components/phone-type/phone-type.component';
import {PhoneValidator} from './formly-components/validators/PhoneValidator';
import {ButtonLinkTypeComponent} from "./formly-components/button-link-type/button-link-type.component";
import {
  DescriptionBlockTypeComponent
} from "./formly-components/description-block-type/description-block-type.component";

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    FormlyMatSliderModule,
    FormlyModule.forRoot({
      validators: [
        {name: 'email', validation: EmailValidator.validator},
        {name: 'dateNotBefore', validation: DateNotBeforeFieldValidator.validator},
      ],
      validationMessages: [
        {name: 'required', message: 'Campo obbligatorio'},
        {name: 'type', message: typeValidationMessage},
        {name: 'minLength', message: minLengthValidationMessage},
        {name: 'maxLength', message: maxLengthValidationMessage},
        {name: 'min', message: minValidationMessage},
        {name: 'max', message: maxValidationMessage},
        {name: 'multipleOf', message: multipleOfValidationMessage},
        {name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage},
        {name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage},
        {name: 'minItems', message: minItemsValidationMessage},
        {name: 'maxItems', message: maxItemsValidationMessage},
        {name: 'uniqueItems', message: 'gli elementi devono essere univoci'},
        {name: 'const', message: constValidationMessage},
        {name: 'email', message: EmailValidator.message},
        {name: 'phone', message: PhoneValidator.message}
      ],
      types: [
        {name: 'null', component: NullTypeComponent, wrappers: ['form-field']},
        {name: 'array', component: ArrayTypeComponent},
        {name: 'object', component: ObjectTypeComponent},
        {name: 'multischema', component: MultiSchemaTypeComponent},
        {name: 'separator', component: SeparatorTypeComponent},
        {name: 'datepicker', component: DatepickerTypeComponent},
        {name: 'datetimepicker', component: DatetimepickerTypeComponent, wrappers: ['form-field']},
        {name: 'button-toggle', component: ButtonToggleTypeComponent},
        {name: 'autocomplete', component: AutocompleteTypeComponent, wrappers: ['form-field']},
        {name: 'hidden', component: HiddenInputTypeComponent},
        {name: 'phone', component: PhoneTypeComponent},
        {name: 'button-link', component: ButtonLinkTypeComponent},
        {name: 'description-block', component: DescriptionBlockTypeComponent},
      ],
    }),
    ReactiveFormsModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    FormlyMaterialModule
  ],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useValue: MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    }
  ],
  // exports: [
  //   SharedModule
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
