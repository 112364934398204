import { Component } from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'nbd-button-link-type',
  templateUrl: './button-link-type.component.html',
  styleUrls: ['./button-link-type.component.scss']
})
export class ButtonLinkTypeComponent extends FieldType<FieldTypeConfig> {

}
