import {Component, OnDestroy, ViewChild} from '@angular/core';
import {HelperService} from '../../shared/helper.service';
import {CoreService} from '../core.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {ToolbarMenuHostDirective} from '../classes/toolbar-menu-host.directive';
import {combineLatestWith, distinctUntilChanged, ReplaySubject, Subscription, tap} from 'rxjs';
import {debug} from '../../shared/rxjs/debug';
import {BaseRxjsComponent} from '../../shared/BaseRxjsComponent';

@Component({
  selector: 'nbd-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends BaseRxjsComponent implements OnDestroy {

  @ViewChild(ToolbarMenuHostDirective) set toolbarMenuHost(value: ToolbarMenuHostDirective) {
    if (value) {
      this.toolbar$.next(value);
    }
  }

  private toolbar$ = new ReplaySubject<ToolbarMenuHostDirective>(1);

  constructor(public hs: HelperService,
              public cs: CoreService,
              public auth: AuthService,
              private location: Location,
              private route: ActivatedRoute,
              private router: Router) {
    super();

    this.sub = this.hs.toolbarComponent$.pipe(
      combineLatestWith(this.toolbar$.pipe(
        distinctUntilChanged()
      )),
      tap(([component, toolbar]) => {
        const viewContainerRef = toolbar.viewContainerRef;
        viewContainerRef.clear();
        if (component) {
          const componentRef = viewContainerRef.createComponent<typeof component.component>(component.componentType);
          componentRef.instance.data = component.data;
        }
      })
    ).subscribe();

  }

  showSecretariatContacts() {
    this.hs.information("Contatti Segreteria", "<p>TELEFONO: <a href=\"tel:+393895549185\"><b>389 55 49 185</b></a></p>" +
      "<p>EMAIL: <a href=\"mailto:segreteria@bancageneralievents.it\"><b>segreteria@bancageneralievents.it</b></a></p>" +
      "<p>Dal lunedì al venerdì dalle 9:00 alle 13:00 e dalle 14:00 alle 18:00</p>" +
      "<p>Periodo di chiusura 12-16 agosto</p>");
  }

  back(): void {
    this.cs.navHistory.pop();
    if (this.cs.navHistory.size() > 0) {
      this.location.back();
    } else if (this.hs.backBtnFallback) {
      this.router.navigate(this.hs.backBtnFallback);
      this.hs.backBtnFallback = undefined;
    } else {
      this.router.navigateByUrl('/');
    }
  }

}
