import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../../auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const updateParams: any = {
      withCredentials: true
    }
    if(this.auth.user$.value) {
      updateParams.setHeaders = {
        Authorization: `JWT ${this.auth.user$.value.token}`
      }
    }

    request = request.clone(updateParams);

    return next.handle(request);
  }
}
