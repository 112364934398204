import { Component } from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'nbd-description-block-type',
  templateUrl: './description-block-type.component.html',
  styleUrls: ['./description-block-type.component.scss']
})
export class DescriptionBlockTypeComponent extends FieldType<FieldTypeConfig> {

}
