import {Injectable} from '@angular/core';
import {combineLatest, Observable, ReplaySubject, switchMap, tap} from 'rxjs';
import {MenuItem} from './classes/menu-item';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {debug} from '../shared/rxjs/debug';
import {filter, map, shareReplay} from 'rxjs/operators';
import {LimitedStack} from '../shared/classes/LimitedStack';
import {AuthService} from '../auth/auth.service';
import {HelperService} from '../shared/helper.service';
import {AppMenu} from './classes/app-menu';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  menuItems$: Observable<AppMenu>;
  currentRoute$ = new ReplaySubject<string>(1);

  navHistory = new LimitedStack<string>(50);

  constructor(private router: Router, private auth: AuthService, private hs: HelperService) {
    this.router.events.pipe(
      tap((event) => {
        if (event instanceof NavigationStart) {
          if (this.navHistory.peek() !== event.url) {
            this.hs.toolbarComponent$.next(null);
            this.hs.showBackBtn$.next(false);
          }
        }
      }),
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).urlAfterRedirects),
      tap((route) => {
        if (this.auth.user$.value && route.startsWith('/auth')) {
          this.router.navigate(['/']);
          return;
        }
        this.navHistory.push(route);
        this.currentRoute$.next(route);
      })
    ).subscribe();


    this.menuItems$ = this.auth.user$.pipe(
      switchMap((user) => combineLatest([this.hs.refSgr$, this.currentRoute$]).pipe(
          map(([sgr, route]) => {
            let menu: AppMenu;
            if (user) {
              let sgrMenu: MenuItem[] = [];
              if (sgr) {
                sgrMenu = [
                  {
                    label: 'Dati Società',
                    url: `/sgr/${sgr.id}`,
                    icon: 'apartment',
                    isActive: false,
                    visible: this.hasRole(['crm-sgr'])
                  }
                ]
              }

              menu = {
                common: [
                  {
                    label: 'Dashboard',
                    url: '/dashboard',
                    icon: 'dashboard',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr', 'checkin'])
                  },
                  {
                    label: 'Utenti',
                    url: '/users',
                    icon: 'group',
                    isActive: false,
                    visible: !this.hasRole(['crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'Gadget',
                    url: '/shipments',
                    icon: 'local_shipping',
                    isActive: false,
                    visible: !this.hasRole(['crm-bg', 'checkin', 'crm-staff'])
                  }
                ],
                crm: [
                  {
                    label: 'Gestione liste',
                    url: '/user-lists',
                    icon: 'format_list_bulleted',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'Messenger',
                    url: '/messenger',
                    icon: 'contact_mail',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'Camere',
                    url: '/rooms',
                    icon: 'bed',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'Voli',
                    url: '/flights',
                    icon: 'flight',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'Tavoli',
                    url: '/meal-tables',
                    icon: 'table_restaurant',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'Attività',
                    url: '/activities',
                    icon: 'surfing',
                    isActive: false,
                    visible: this.hasRole(['admin', 'crm', 'crm-staff'])
                    // visible: this.auth.role() === 'admin'
                  },
                  {
                    label: 'Email Editor',
                    url: '/templates',
                    icon: 'email',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'SGR',
                    url: '/sgr',
                    icon: 'apartment',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                  {
                    label: 'Log',
                    url: '/crm-log',
                    icon: 'storage',
                    isActive: false,
                    visible: !this.hasRole(['crm-sgr','crm-bg', 'checkin', 'crm-staff'])
                  },
                ],
                sgr: sgrMenu,
                admin: [
                  {
                    label: 'Form Builder',
                    url: '/form-builder',
                    icon: 'dynamic_form',
                    isActive: false,
                    visible: this.auth.role() === 'admin'
                  },
                ],
                staff: [
                  {
                    label: 'Controllo Accessi',
                    url: '/gate',
                    icon: 'qr_code_scanner',
                    isActive: false,
                    visible: this.hasRole(['admin', 'crm-staff', 'checkin'])
                  }
                ]
              };
            } else {
              menu = {
                common: [],
                crm: [],
                sgr: [],
                admin: [],
                staff: []
              };
            }

            (Object.keys(menu) as (keyof typeof menu)[]).forEach(
              (category) => menu[category].forEach(item => {
                item.isActive = route.startsWith(item.url)
              }));

            return menu;
          }),
        )
      ),
      shareReplay(1),
      debug('Menu')
    );

  }

  private hasRole(roles: string[]) {
    return roles.includes(this.auth.role());
  }
}
