<!--<mat-form-field [appearance]="props.appearance ?? 'outline'" class="w-full">-->

<!--  <mat-error *ngIf="formControl.hasError('dateNotBefore')">-->
<!--    TEST-->
<!--  </mat-error>-->
<!--</mat-form-field>-->

<input
  matInput
  type="datetime-local"
  [id]="id"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [max]="fromISOToLocal(props.datepickerOptions?.max)"
  [min]="fromISOToLocal(props.datepickerOptions?.min)"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
  [readonly]="props.readonly"
  [required]="required"
/>
