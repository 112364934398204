import {Component, Input} from '@angular/core';
import {MenuItem} from '../classes/menu-item';

@Component({
  selector: 'nbd-nav-menu-item',
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss']
})
export class NavMenuItemComponent {

  @Input() m?: MenuItem;
}
