import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import {FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'nbd-hidden-input-type',
  templateUrl: './hidden-input-type.component.html',
  styleUrls: ['./hidden-input-type.component.scss']
})
export class HiddenInputTypeComponent extends FieldType<FieldTypeConfig> {}
