import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {canMatchAuthenticated} from './auth/classes/canMatchAuthenticated';
import {NotFoundComponent} from './core/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'gate',
    loadChildren: () => import('./checkin/checkin.module').then(m => m.CheckinModule),
    canMatch: [canMatchAuthenticated(['checkin', 'crm-staff'])]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'user-lists',
    loadChildren: () => import('./user-lists/user-lists.module').then(m => m.UserListsModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'flights',
    loadChildren: () => import('./flights/flights.module').then(m => m.FlightsModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'meal-tables',
    loadChildren: () => import('./meal-tables/meal-tables.module').then(m => m.MealTablesModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
    canMatch: [canMatchAuthenticated(['crm-staff'])]
  },
  {
    path: 'sgr',
    loadChildren: () => import('./sgr/sgr.module').then(m => m.SgrModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'shipments',
    loadChildren: () => import('./shipments/shipments.module').then(m => m.ShipmentsModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'rooms',
    loadChildren: () => import('./rooms/rooms.module').then(m => m.RoomsModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'test',
    loadChildren: () => import('./tester/tester.module').then(m => m.TesterModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'messenger',
    loadChildren: () => import('./messenger/messenger.module').then(m => m.MessengerModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'form-builder',
    loadChildren: () => import('./form-builder/form-builder.module').then(m => m.FormBuilderModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'templates',
    loadChildren: () => import('./html-template-editor/html-template-editor.module').then(m => m.HtmlTemplateEditorModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canMatch: [canMatchAuthenticated(['crm-staff'])]
  },
  {
    path: 'crm-log',
    loadChildren: () => import('./crm-log/crm-log.module').then(m => m.CrmLogModule),
    canMatch: [canMatchAuthenticated()]
  },
  {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enableTracing: true
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
