import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormlyFieldConfig, FormlyModule} from '@ngx-formly/core';
import {ArrayTypeComponent} from './array-type/array-type.component';
import {NullTypeComponent} from './null-type/null-type.component';
import {ObjectTypeComponent} from './object-type/object-type.component';
import {MultiSchemaTypeComponent} from './multi-schema-type/multi-schema-type.component';
import {MatListModule} from '@angular/material/list';
import {SeparatorTypeComponent} from './separator-type/separator-type.component';
import {DatepickerTypeComponent} from './datepicker-type/datepicker-type.component';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLuxonDateModule} from '@angular/material-luxon-adapter';
import {DatetimepickerTypeComponent} from './datetimepicker-type/datetimepicker-type.component';
import {ButtonToggleTypeComponent} from './button-toggle-type/button-toggle-type.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {FormlySelectModule} from '@ngx-formly/core/select';
import {AutocompleteTypeComponent} from './autocomplete-type/autocomplete-type.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { HiddenInputTypeComponent } from './hidden-input-type/hidden-input-type.component';
import {PhoneTypeComponent} from './phone-type/phone-type.component';
import {ButtonLinkTypeComponent} from "./button-link-type/button-link-type.component";
import {MatAnchor} from "@angular/material/button";
import {DescriptionBlockTypeComponent} from "./description-block-type/description-block-type.component";

export function minItemsValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve contenere almeno ${field.props?.['minItems']} element`;
}

export function maxItemsValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve contenere meno di ${field.props?.['maxItems']} elementi`;
}

export function minLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `lunghezza minima: ${field.props?.['minLength']} caratteri`;
}

export function maxLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `lunghezza massima: ${field.props?.['maxLength']} caratteri`;
}

export function minValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve essere >= ${field.props?.['min']}`;
}

export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve essere <= ${field.props?.['max']}`;
}

export function multipleOfValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve essere multiplo di ${field.props?.['step']}`;
}

export function exclusiveMinimumValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve essere > ${field.props?.['step']}`;
}

export function exclusiveMaximumValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve essere < ${field.props?.['step']}`;
}

export function constValidationMessage(error: any, field: FormlyFieldConfig) {
  return `deve essere uguale a "${field.props?.['const']}"`;
}

export function typeValidationMessage({schemaType}: any) {
  return `deve essere di tipo "${schemaType[0]}".`;
}

@NgModule({
  declarations: [
    ArrayTypeComponent,
    NullTypeComponent,
    ObjectTypeComponent,
    MultiSchemaTypeComponent,
    SeparatorTypeComponent,
    DatepickerTypeComponent,
    DatetimepickerTypeComponent,
    ButtonToggleTypeComponent,
    AutocompleteTypeComponent,
    HiddenInputTypeComponent,
    ButtonLinkTypeComponent,
    DescriptionBlockTypeComponent,
    PhoneTypeComponent
  ],
  imports: [
    CommonModule,
    FormlyModule.forChild(),
    MatListModule,
    MatInputModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    FormlySelectModule,
    MatAutocompleteModule,
    MatAnchor
  ]
})
export class FormlyComponentsModule {
}
