import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {NavigationComponent} from './navigation/navigation.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {AuthModule} from '../auth/auth.module';
import { NotFoundComponent } from './not-found/not-found.component';
import {ToolbarMenuHostDirective} from './classes/toolbar-menu-host.directive';
import {SharedModule} from '../shared/shared.module';
import { NavMenuItemComponent } from './nav-menu-item/nav-menu-item.component';


@NgModule({
  declarations: [
    NavigationComponent,
    NotFoundComponent,
    ToolbarMenuHostDirective,
    NavMenuItemComponent
  ],
  exports: [
    NavigationComponent,
    AuthModule
  ],
    imports: [
        CommonModule,
        RouterLink,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        AuthModule,
        RouterLinkActive,
        RouterOutlet,
        SharedModule
    ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    console.log("Core Module init");
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
