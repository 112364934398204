<mat-form-field [appearance]="props.appearance ?? 'outline'" class="w-full">

  @if (props.label) {
    <mat-label>{{ props.label }}</mat-label>
  }


  <input type="text"
    [id]="id"
    [formControl]="formControl"
    matInput
    [errorStateMatcher]="errorStateMatcher"
    (focus)="phoneInput.focus()"
    [required]="required"
    class="absolute !w-0 !h-0">
    <div matTextPrefix class="w-[48px]">
      <!--        <mat-icon>visibility</mat-icon>-->
    </div>
    <input #phoneInput
      class="z-10 dark:[color-scheme:dark] dark:text-white"
      (keyup)="formControl.patchValue(telInput?.getNumber())"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder ?? ''"
      [readonly]="props.readonly"
      type="tel"/>

      <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
      <!--  @if(formControl.errors) {-->
      <!--    @for(error of formControl.errors | keyvalue; track error.key) {-->
      <!--      <mat-error>-->
      <!--        error.value-->
    <!--      </mat-error>-->
    <!--    }-->
    <!--  }-->


  </mat-form-field>


  <!--<mat-error class="text-white" *ngIf="formControl.invalid">-->
  <!--  Numero di telefono non valido-->
<!--</mat-error>-->
