<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
  [errorStateMatcher]="errorStateMatcher"
  />
  <mat-autocomplete #auto="matAutocomplete">
    @for (value of filter | async; track value) {
      <mat-option [value]="value">
        {{ value }}
      </mat-option>
    }
  </mat-autocomplete>
