@if (m) {
  @if (m.visible) {
    <mat-list-item
      [activated]="m.isActive"
      [routerLink]="[m.url]">
      <mat-icon matListItemIcon>{{m.icon}}</mat-icon>
      <a matListItemTitle [innerHTML]="m.label"></a>
    </mat-list-item>
  }
}
