@if (auth.user$ | async; as user) {
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(hs.isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(hs.isHandset$ | async) ? 'over' : 'side'"
      [opened]="(hs.isHandset$ | async) === false">
      <mat-toolbar>
        <div class="p-3 w-full h-full bg-contain bg-no-repeat bg-center mt-4" style="background-image: url(./assets/img/logo.png)">
        </div>
      </mat-toolbar>
      @if (cs.menuItems$ | async; as menuItems) {
        <mat-nav-list>
          @if (menuItems.sgr.length > 0) {
            @for (m of menuItems.sgr; track m) {
              <nbd-nav-menu-item [m]="m"></nbd-nav-menu-item>
            }
          }
          @if (menuItems.common.length > 0) {
            @for (m of menuItems.common; track m) {
              <nbd-nav-menu-item [m]="m"></nbd-nav-menu-item>
            }
          }
          @if (menuItems.crm.length > 0) {
            @for (m of menuItems.crm; track m) {
              <nbd-nav-menu-item [m]="m"></nbd-nav-menu-item>
            }
          }
          @if (menuItems.staff.length > 0) {
            @for (m of menuItems.staff; track m) {
              <nbd-nav-menu-item [m]="m"></nbd-nav-menu-item>
            }
          }
          @if (menuItems.admin.length > 0) {
            <mat-divider></mat-divider>
            @for (m of menuItems.admin; track m) {
              <nbd-nav-menu-item [m]="m"></nbd-nav-menu-item>
            }
          }
        </mat-nav-list>
      }
      @if (hs.refSgr$ | async; as sgr) {
        @if (!sgr.finalized) {
          <button mat-raised-button
            (click)="hs.confirmData$.next(sgr.id)"
            color="primary"
            class="!py-4 mx-4 !h-auto">
            INVIA ALLA SEGRETERIA I DATI INSERITI
          </button>
        }
        @if (sgr.finalized) {
          <div class="status-badge confirmation-bg mx-4 text-center p-4">
            DATI CONFERMATI
          </div>
        }
        <button mat-raised-button
          (click)="showSecretariatContacts()"
          color="accent"
          class="!py-4 mt-4 mx-4 !h-auto">
          CONTATTI SEGRETERIA
        </button>
      }
    </mat-sidenav>
    <mat-sidenav-content class="!grid grid-cols-1 grid-rows-[max-content_1fr] overflow-hidden">
      <mat-toolbar color="primary">
        @if (user.role !== 'checkin' && (hs.isHandset$ | async)) {
          <button
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            >
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
        }
        @if ((hs.showBackBtn$ | async) && cs.navHistory.size() > 0) {
          <div class="flex">
            <button mat-fab extended color="primary" class="mat-no-shadow" (click)="back()">
              <mat-icon>arrow_back_ios</mat-icon>
              Indietro
            </button>
          </div>
        }
        <ng-template toolbarMenuHost>
        </ng-template>
        <nbd-user-menu class="ml-auto"></nbd-user-menu>
      </mat-toolbar>
      <div class="w-full h-full overflow-y-auto relative">
        <!--      <ng-content></ng-content>-->
        <router-outlet></router-outlet>
        <!--      <div class="absolute w-full h-full top-0 left-0 bg-gray-50/60" -->
        <!--           *ngIf="hs.loading$ | async"-->
        <!--           style="z-index: 1000">-->
        <!--        <nbd-loader [fill]="true"></nbd-loader>-->
      <!--      </div>-->
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
} @else {
  <router-outlet></router-outlet>
  <!--  <ng-content></ng-content>-->
}
